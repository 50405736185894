<template>
  <div class="container max-w-md px-4 pt-4 mx-auto overflow-x-hidden">
    <div class="flex items-center justify-center w-full mt-16">
      <button @click="$store.commit('recipes/setView', true)" :class="['flex items-center justify-center py-2 text-sm border focus:outline-none rounded-l-md w-28 transition-all transform duration-100', view ? 'bg-gray-700 border-transparent' : 'bg-gray-900 bg-opacity-50 border-gray-700 border-opacity-50']">
        <svg class="w-5 h-5 mb-px mr-2 opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
        Liste
      </button>
      <button @click="$store.commit('recipes/setView', false)" :class="['flex items-center justify-center py-2 text-sm border focus:outline-none rounded-r-md w-28 transition-all transform duration-100', !view ? 'bg-gray-700 border-transparent' : 'bg-gray-900 bg-opacity-50 border-gray-700 border-opacity-50']">
        <svg class="w-5 h-5 mb-px mr-2 opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
        Galerie
      </button>
    </div>
    <div :class="['mt-6', !view ? 'section' : 'p-4 pt-0']">
      <recipe v-for="recipe in recipes" :key="recipe.id" :id="recipe.id" />
    </div>

    <div @click="searchPanel = false" :class="['fixed inset-0 transform transition-all duration-200 bg-gray-900', searchPanel ? 'pointer-events-auto opacity-75' : 'pointer-events-none opacity-0']"></div>
    <div :class="['absolute top-20 left-1/2 max-w-sm w-10/12 sm:w-auto -translate-x-1/2 px-4 transition-all duration-200 transform rounded-md border border-transparent hover:bg-gray-700 focus:bg-gray-700', searchPanel ? 'bg-gray-700 border-gray-600 rounded-b-none' : '']">
      <div class="relative z-20 w-full max-w-xs mx-auto border-b border-gray-600">
        <svg class="absolute left-0 w-4 h-4 ml-2 text-gray-400 transform -translate-y-1/2 top-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        <input v-model="search" @focus="searchPanel = true" @blur="searchPanel = false" type="text" class="w-full py-2 ml-8 text-white placeholder-gray-400 bg-transparent focus:outline-none" placeholder="Suchen" >      
      </div>
      <ul :class="['absolute inset-x-0 z-10 pt-1 -m-px flex flex-col transition-all duration-200 transform origin-top bg-gray-700 border border-t-0 border-gray-600 rounded-b-md top-10', searchPanel ? 'scale-x-100 scale-y-100 opacity-100' : 'scale-x-90 scale-y-0 h-0 opacity-0']">
        <li @click="$router.push('/recipes/'+recipe.id)" class="flex items-center px-2 py-1 cursor-pointer hover:bg-primary-600" v-for="recipe in searchResults" :key="recipe.id">
          {{recipe.title}}
        </li>
      </ul>
    </div>

    <button @click="$router.push('/recipes/new')" class="fixed p-4 text-white rounded-full shadow bg-primary-600 bottom-8 right-6 focus:outline-none">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
    </button>
  </div>
</template>

<script>
import recipe from '@/components/recipes/recipe'

export default {
  components: { recipe },
  data: () => ({
    searchPanel: false,
    search: ''
  }),
  computed: {
    view: vm => vm.$store.getters['recipes/view'],
    recipes: vm => vm.$store.getters['recipes/all'],
    searchResults: vm => vm.$store.getters['recipes/all'].filter(recipe => recipe.title.toLowerCase().match(vm.search.toLowerCase()))
  }
}
</script>

<style scoped>
.section {
  display: flex;
  gap: 20px;
  max-width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 1rem;
  padding: 0 1rem 1rem;
  border-radius: 5px;
}
</style>