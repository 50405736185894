<template>
  <div :class="['transform transition-all duration-300', !view ? 'recipe pb-16' : 'mb-4']">
    <div @click="$router.push('/recipes/'+id)" :class="['relative w-full bg-gray-700 rounded-md shadow transform transition-all duration-300', view ? 'h-20 flex items-center' : 'h-full']">
      <img :src="recipe.imageUrl" :class="['object-cover object-center h-full rounded-md', !view ? 'w-full' : 'w-24 rounded-r-none']" v-if="recipe.imageUrl">
      <div :class="['flex w-full px-2 text-xl font-medium leading-tight', !view ? 'absolute bottom-0 left-0 h-16 items-center justify-center text-center transform translate-y-16' : 'ml-2 items-start max-h-12 justify-start overflow-ellipsis overflow-hidden']">
        {{recipe.title}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data: () => ({
    details: false
  }),
  computed: {
    view: vm => vm.$store.getters['recipes/view'],
    recipe: vm => vm.$store.getters['recipes/single'](vm.id)
  }
}
</script>

<style scoped>
.recipe {
  flex: 0 0 100%;
  height: calc(100vh - 14rem);
  scroll-snap-align: start;
}
</style>